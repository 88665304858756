import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography } from "@mui/material";
import TellMeMore from "./TellMeMore";
import { UserContext } from "../context/UserContext";

function Footer(props) {
  const { opened, setOpened } = useContext(UserContext);

  return (
    <div className="footer">
      <Container>
        <Grid container spacing={3} justify="center" className="footer-inner">
          <Grid item xs={12} sm={6} md={2}>
            <div className="footer-lists">
              <Link to="#!" onClick={() => setOpened(true)}>
                <Typography variant="h4">CONTACT US</Typography>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <div className="footer-lists">
              <Link to="/the-team">
                <Typography variant="h4">The Team</Typography>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <div className="footer-lists">
              <Link to="/terms-of-use">
                <Typography variant="h4">Terms of Use</Typography>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <div className="footer-lists">
              <Link to="/privacy-policy">
                <Typography variant="h4">Privacy Policy</Typography>
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <div className="fc-logo-block">
              <a href="https://www.faircent.com" target="_blank">
                <div className="fc-logo">
                  <img src="../../assets/images/logo.png" />
                </div>
              </a>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={0} className="footer-disclamier">
          <Grid item xs={12} sm={12} md={12}>
            <div className="disclamier-content">
              <Typography variant="body1">
                <span className="disc-title">Disclaimer:</span>{" "}
                <strong>
                  FTIPL PAYMENTS AND MARKETING TECH PRIVATE LIMITED
                </strong>{" "}
                (FaircentPro.com) is a company registered under the Companies
                Act, 2013 and provides a internet based platform to connect its
                registered borrowers with various NBFC’s and Financial
                Institutions registered on its platform, for availing loan
                facilities offered by such NBFC’s and Financial Institutions.
                However, the RBI does not accept any responsibility or guarantee
                about the present position as to the financial soundness of the
                company or for the correctness of any of the statements or
                representations made or the opinions expressed by the company
                and does not provide any assurance for repayment of the loans
                lent through the company/ deposits / discharge of liabilities by
                the company.
              </Typography>
              <Typography variant="body1">
                Lending on FaircentPro.com is subject to defaults. Please read
                the product details/borrower profiles carefully before
                investing. The information contained herein is only to enable
                the Lenders to make a considered decision. Any decision taken by
                the Lenders on the basis of this information is the sole
                responsibility of the Lender and FaircentPro is not liable for
                the default of the borrowers. This information does not include
                any sensitive personal data or information of the Borrower.
                FaircentPro only facilitates a virtual meeting place between the
                Borrowers and the Lenders registered on its online platform. The
                decision to lend is entirely at the discretion of the Lender and
                FaircentPro does not guarantee that the Borrowers will receive
                any loans from the Lenders. FaircenPro merely aids and assist
                the Lenders and the Borrowers listed on its website to make and
                receive loans and charges a service fee from the Lenders and the
                Borrowers for such assistance. FaircentPro is only an
                ‘Intermediary’ under the provisions of the Information
                Technology Act, 1999.
              </Typography>
              <Typography variant="body1">
                The ratings given for each Borrower, Product or Partner is based
                on an internal mechanism developed by FaircentPro taking into
                account various factors such as quality of borrowers, their
                credit history, the tenure of loans, diversity of portfolio and
                the partners vintage, reach, control over funds, credit history,
                strength of management and assistance or collection support
                provided, which FaircentPro believes in good faith may assist
                lenders in their selection of Borrower, Product or Partner.
                FaircentPro is not a rating agency and the factors considered to
                rate a Borrower are not industry approved factors nor approved
                by any regulatory authority. You may therefore choose to rely on
                these ratings at your sole discretion and FaircentPro will not
                be liable or responsible for your reliance on these ratings. The
                data provided above is purely for information purposes and being
                dynamic, is true as of the date of publishing but can change
                immediately thereafter and FaircentPro is not accountable for
                its authenticity or veracity. Technology is susceptible to
                downtime, errors, viruses and malware, which could have an
                adverse impact on the information displayed during such downtime
                or during the time when the Technology is affected with errors,
                viruses and malware; so we advise that information available on
                the platform should be verified intermittently a few times,
                before making a decision.
              </Typography>
            </div>
            <div className="copyright">
              <Typography variant="body1">
                Copyright &copy; FaircentPro 2021. All Rights Reserved.
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
      <TellMeMore opened={opened} setOpened={setOpened} />
    </div>
  );
}

export default Footer;
